export const educationDatabase = [
    {title: 'Software Engineering', degree: 'Bachelor´s degree', school: 'Oslo Metropolitan University', year: '2022 - Present', description: 'The program covers a wide range of topics within Computer Science and Software Engineering, such as mathematics, programming, algorithms and datastructures, databases, web development, app development, software testing, cyber security, computer networks, computer architecture, operating systems and more.'},
    {title: 'Pre-Engineering', degree: '1-year course', school: 'Oslo Metropolitan University', year: '2019 - 2020', description: 'Required to be able to apply for the Engineering program.'},
    {title: 'Computer Electronics', degree: 'Trade Certificate', school: 'Elvebakken VGS, Sogn VGS and Intility', year: '2009 - 2014', description: 'The first three years covered topics such as electrical systems, electronics, programming, servers, computer hardware, computer architecture and computer networks. The last 18 months were an apprenticeship at Intility, where I worked as an IT support technician.'},
];

export const experienceDatabase = [
    {title: 'Incident Responder', company: 'Intility', year: '2017 - 2022', description: 'Operations, monitoring and incident management of network and server infrastructure, as well as a wide range of business applications and services on Intility´s IT platform. Handle incidents and alerts from our monitoring system, troubleshooting and resolving incidents, or escalating complex or critical incidents. Common incidents included network outages, comline issues, server outages, resource usage on servers and networks, issues with applications or services on serverc, etc. Other operational tasks could include server termination, troubleshooting and adjusting resource usage on servers, expanding disks on servers, server backups, database backups, restoring servers and files from backups, and similar tasks. I worked with technologies such as Windows Server, Linux, Cisco, VMWare, Azure, Splunk, as well as Intility´s monitoring systems. Training of support technicians in monitoring and incident management. Worked part-time from August 2019 to December 2020 when I was studying.'},
    {title: 'Senior Technician', company: 'Intility', year: '2015 - 2017', description: 'Distribute tickets, help other support technicians and handle more complex tickets. Training of apprentices and new employees. 3 months in Auckland, New Zealand, where I would handle business-critical incidents happening during the night (Norwegian time). 12 months at Intility`s office in Singapore, where I contributed with the establishment of 24/7 support, monitoring and operations from Singapore. I also provided on-site support for a customer in Singapore and assisted on-site during the onboarding of a new customer office in Hong Kong.'},
    {title: 'Technician', company: 'Intility', year: '2014 - 2015', description: 'Technical support with specialization in the business applications of Intility`s customers. Training of apprentices and new employees.'},
    {title: 'Technical Apprentice', company: 'Intility', year: '2012 - 2014', description: 'Apprenticeship. Technical support via phone, e-mail and chat.'},
];

export const projectDatabase = [
    {title: 'Bachelor´s project', github: '/', url: '', organization: 'Decisive AS', year: 'Spring 2025', description: 'Starting in January 2025, we will begin our bachelor´s project with Decisive AS. One of their customers uses an HTTP-based message queue for sending messages between applications in an event-driven architecture. The current solution is pull-based, and our task is to improve performance by creating two new push-based solutions. One solution will test the use of Long Polling, and the other will test the use of Server-Sent Events. We will then evaluate which solution is best suited. The solutions will be built with Spring Boot using Kotlin, and PostgreSQL will be used as the database with R2DBC. The database will run in a Docker image.', technologies: ['Kotlin', 'Spring Boot', 'PostgreSQL', 'R2DBC', 'Docker']},
    {title: 'ITPE3200 - Web Applications', github: 'https://github.com/vetlelg/ITPE3200-Web-Applications', url: '', organization: 'Oslo Metropolitan University', year: 'Fall 2024', description: 'School project where we developed a map application for registering and storing markers on a map, including information about the location, images, and comments. We initially used ASP.NET MVC, followed by creating a similar project with React and ASP.NET Web API. The Google Maps JavaScript API was used for the map. EF Core and SQLite were utilized as the database technology. The design was created using Bootstrap and CSS. This is a full-stack web application that includes both server-side and client-side input validation, error handling, logging, and unit testing, as well as user registration, cookie authentication, and authorization with ASP.NET Identity.', technologies: ['React', 'JavaScript', 'ASP.NET', 'EF Core', 'C#', 'Bootstrap', 'SQLite', 'HTML/CSS', 'Google Maps JavaScript API']},
    {title: 'DAVE3600 - App Development', github: 'https://github.com/vetlelg/DAVE3600-App-Development', url: '', organization: 'Oslo Metropolitan University', year: 'Fall 2024', description: 'App development projects for Android using Java. Created an app for simple math exercises, an app for storing phone numbers and automatic sending of birthday-SMS to friends, and an app for storing markers on a map in an SQL database using the Google Maps Android SDK.', technologies: ['Java', 'Android', 'Google Maps Android SDK']},
    {title: 'vetlelg.com', github: 'https://github.com/vetlelg/vetlelg-frontend', url: 'https://vetlelg.com', organization: 'Personal project', year: 'Fall 2024', description: 'Portfolio website for showcasing my projects and resume. Hosted in Azure using Azure Static Web Apps with automatic deployment from GitHub.', technologies: ['React', 'Bootstrap', 'JavaScript', 'HTML/CSS', 'Azure'] },
    {title: 'DATA2410 - Networking', github: 'https://github.com/vetlelg/DATA2410-DRTP', url: '', organization: 'Oslo Metropolitan University', year: 'Spring 2024', description: 'School project in socket programming with Python. A file transfer application for sending files over a network between a client and a server. The application uses UDP and implements TCP-like functionality with Python at the application layer on top of UDP. This includes a three-way handshake to connect the client to the server, a Go-Back-N sliding window protocol for data transfer, and a two-way handshake for connection teardown.', technologies: ['Python', 'Socket library']},
    {title: 'DATA1700 - Webprogramming', github: 'https://github.com/vetlelg/DATA1700-Webprogramming', url: '', organization: 'Oslo Metropolitan University', year: 'Spring 2023', description: 'School projects in full-stack web programming using Java Spring Boot, JavaScript, Bootstrap, and SQL. This course also covers topics such as client-side and server-side input validation, error handling and logging, cookie authentication, sessions, and hashing and salting of passwords. In the first part of the course, we used manual SQL queries, and later transitioned to using ORM (JPA).', technologies: ['Java', 'Spring Boot', 'SQLite', 'JavaScript', 'Bootstrap'] },
    {title: 'DATA1200 - Inclusive Web Design', github: 'https://github.com/vetlelg/final-assignment', url: '', organization: 'Oslo Metropolitan University', year: 'Fall 2022', description: 'School project in inclusive and responsive web design using only HTML and CSS. The course covers topics such as semantic HTML, CSS Box Model, Flexbox, Grid, inclusive web design, WCAG and more.', technologies: ['HTML', 'CSS']},
    {title: 'The Fountain of Objects', github: 'https://github.com/vetlelg/csharpplayersguide/tree/main/bossbattles/TheFountainOfObjects', url: '', organization: 'Personal Project', year: 'Fall 2021', description: 'A fun and simple console game I made when learning C#. This was part of the book "The C# Player´s Guide" by RB Whitaker.', technologies: ['C#']},
    {title: 'Snake', github: 'https://github.com/vetlelg/sfml-snake', url: '', organization: 'Personal Project', year: 'Fall 2017', description: 'My first object-oriented programming project I created when trying to learn C++', technologies: ['C++', 'SFML']},
];