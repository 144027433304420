const About = () => (
        <div id="about" className="row pt-5">
            <h6 className="fw-bold">ABOUT</h6>
            <p className="pt-4">
                I'm a software engineering student, with a background as an IT technician. After working for some years, I decided to go to university to pursue a degree in software engineering.
            </p>
            <p>
                I have experience with the following languages, frameworks and libraries through my studies, projects I've worked with, courses and certifications:
                <br/>.NET, C#, Spring Boot, Java, Kotlin, React, JavaScript, Bootstrap, CSS, HTML, SQL, Python
            </p>
            <p>
                I'm currently looking for new opportunities to gain more experience and learn new things.
            </p>
            <img className="mt-5 d-md-none col-10 col-sm-8 mx-auto rounded-circle" src="/profile.JPEG" alt="Vetle L. Gundersen" />
        </div>
);

export default About;
